// Load Styles
import '../scss/main.scss';

import {Modal} from "bootstrap";

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// From submit
$(function () {
  $("#contactSubmit .spinner-border").hide();

  $("#contact-form").submit(function (event) {
    let formData = {
      name: $("#inputName").val(),
      phone: $("#inputPhone").val(),
      email: $("#inputEmail").val(),
      address: $("#inputAddress").val(),
      site_name: $("#inputSiteName").val(),
      subject: $("#inputSubject").val(),
      message: $("#inputMessage").val(),
      partner: $("#inputPartner").val(),
    };

    $("#contactSubmit").attr("disabled", true);
    $("#contactSubmit .spinner-border").show();

    $.ajax({
      type: "POST",
      url: "/api.php",
      data: formData,
      dataType: "json",
      encode: true,
    })
      .done(function (data) {
        $("#contact-form input, #contact-form textarea").val('');
        $("#contactSubmit").removeAttr("disabled");
        $("#contactSubmit .spinner-border").hide();
        window.alert(data.message);
      })
      .fail(function (data) {
        if (data.status === 404) {
          window.alert('❌ Sikertelen üzenet küldés! Kérjük vegye fel a kapcsolatot az ügyfélszolgálattal.');
        } else {
          window.alert(data.responseJSON.message);
        }
      })
      .always(function () {
        $("#contactSubmit").removeAttr("disabled");
        $("#contactSubmit .spinner-border").hide();
      });

    event.preventDefault();
  });
});

// Search
$(function () {

  let adatlapTableRender = function(payload) {
    let adatlapTablazat = $('div.adatlap-tablazat');
    let html = '<table class="table">';
    // html += '<thead><tr><th>Név</th></tr></thead>';
    html += '<tbody>';
      payload.forEach((item) => {
        html += '<tr>';
          html += '<td>';
            html += '<a href="/adatlapok/' + item.file +'" target="_blank">' + item.name + '</a>';
          html += '</td>';
        html += '</tr>';
      });
      html += '</tbody>';
      if (payload.length === 0) {
        html += '<tr>';
          html += '<td>';
            html += 'Nincs találat.';
          html += '</td>';
        html += '</tr>';
      }
    html += '</table>';
    adatlapTablazat.html(html);
  }

  let adatlapKereso = document.getElementById('adatlap-kereso');
  if (adatlapKereso !== null) {
    $("#adatlap-kereso").submit(function (event) {
      let formData = {
        q: $("#inputSearch").val()
      };

      $("#submitSearch .spinner-border").show();

      $.ajax({
        type: "GET",
        url: "/adatlapok.php",
        data: formData,
        dataType: "json",
        encode: true,
      })
        .done(function (data) {
          adatlapTableRender(data);
        })
        .fail(function (data) {
          $('.adatlap-statusz').html('⚠️ A betöltés nem sikerült! Kérjük próbálja meg később.');
        })
        .always(function () {});

      event.preventDefault();
    });

  }
});

window.onload = function() {
  // $('#infoModal').show();
  let modalbox = document.getElementById('infoModal');
  if (modalbox !== null) {
    let infoModal = new Modal(modalbox);
    infoModal.show();
  }
}
